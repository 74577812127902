<template>
  <section class="container mb-14 sm:mb-20">
    <div class="container__inner pt-6 md:pt-12">
      <h1 v-if="header" class="text-royalblue-900 mb-10">{{ header }}</h1>
      <div
        :class="textColClasses"
        class="flex md:flex-row flex-col justify-between -mt-4 -mx-4"
      >
        <div
          class="
            w-full
            md:w-1/2
            p-4
            pb-0
            flex flex-col
            md:items-start
            mb-8
            md:mb-0
          "
        >
          <slot name="text-col"></slot>
        </div>
        <div :class="addtionalPicClasses" class="w-full md:w-1/2 p-4 pb-0">
          <slot name="pic-col"></slot>
        </div>
      </div>

      <div class="mb-5 pb-3">
        <div class="w-full">
          <slot></slot>
        </div>
      </div>

      <slot name="feature-cols"></slot>
    </div>
  </section>
</template>

<script>
export default {
  name: "DetailLayoutWithPic",
  props: {
    header: {
      Type: String,
      required: false,
    },
    addtionalPicClasses: {
      Type: String,
      required: false,
    },
    textColClasses: {
      Type: String,
      required: false,
      default: "mb-8 sm:mb-14",
    },
  },
};
</script>
