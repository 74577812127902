<template>
  <detail-layout-with-pic header="The Services We Provide">
    <template v-slot:text-col>
      <ul>
        <li>Specialist assessment of complex needs</li>
        <li>Goal directed, patient specific treatment plans</li>
        <li>Detailed movement analysis and rehabilitation</li>
        <li>Treatment of dizziness and balance problems</li>
        <li>Strength and conditioning programs</li>
        <li>Advice on: postural management, fatigue and pain</li>
        <li>Specialist soft tissue techniques</li>
        <li>
          Assessment and provision of: mobility aids, FES and lycra garments.
        </li>
        <li>Create self-management programs</li>
        <li>Liaison with carers and care givers</li>
        <li>Sign posting to other local facilities</li>
      </ul>
    </template>
    <template v-slot:pic-col>
      <img
        class="object-cover object-center rounded"
        alt="Patient cutting grass"
        src="../assets/images/cutting_grass.jpg"
        width="474"
        height="593"
      />
    </template>
    <h2 class="mb-5">
      Our Team has extended training, knowledge and skills within the following
      areas:
    </h2>
    <ul>
      <li>Stroke</li>
      <li>Parkinson’s Disease</li>
      <li>Multiple Sclerosis</li>
      <li>Spinal Cord Injury</li>
      <li>Vestibular Rehabilitation</li>
    </ul>
  </detail-layout-with-pic>
</template>

<script>
import DetailLayoutWithPic from "../layouts/DetailLayoutWithPic.vue";

export default {
  name: "Services",
  components: {
    "detail-layout-with-pic": DetailLayoutWithPic,
  },
};
</script>
